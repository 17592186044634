.footbalPrediction_wrapper {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4A0404;
}

.fpw_banner {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.home-sports-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4A0404;
}

.banner-img-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin-top: -1em;
}

.home-banner-img-container {
  width: 100%;
  /* height: 250px; */
  overflow: hidden;
  margin-top: -1em;
}

.banner-img-container img {
  width: 100%;
  max-width: 300%;
  min-height: 320px;
  margin-top: -5em;
}

.home-banner-img-container img {
  width: 100%;
  min-height: 320px;
  margin-top: -5em;
}

.fpw_banner img {
  width: 100%;
  min-height: 320px;
  margin-top: -5em;
}

.fpw_content, .boxing_fpw_content {
  position: absolute;
  left: 40px;
}

.home_fpw_content {
  position: absolute;
  top: 0px;
  left: 40px;
}

.fpw_content h1, .tennis_fpw_content h1, .boxing_fpw_content h1 {
  color: #fff;
  font-weight: 800;
  line-height: 68px;
  font-size: 3rem;
  font-style: normal;
}

.tennis_fpw_content h1 {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.basketball_fpw_content h1 {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.boxing_fpw_content h1 {
  /* position: absolute; */
  top: 140px;
  right: 140px;
}

.home_fpw_content h1, .basketball_fpw_content h1 {
  color: #fff;
  font-weight: 800;
  line-height: 68px;
  font-size: 4rem;
  font-style: normal;
  margin: 2em 0 10px 0;
}

@media (max-width: 1430px) {
  .home_fpw_content h1 {
    font-size: 3rem;
    margin: 1.5em 0 10px 0;
  }
}

@media (max-width: 1170px) {
  .home_fpw_content h1 {
    font-size: 3rem;
    margin: 1em 0 0px 0;
  }
}

.fqw_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.fqw_content h2 {
  color: #fff;
  font-size: 2.5rem;
  text-align: left;
  margin-top: 10px;
}

.fqw_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
}

.fqw_box {
  width: 49%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background: #652929;
  border: 1px solid #c40027;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px;
  position: relative;
}

.fqw_box h5 {
  color: #fff;
  text-align: left;
  font-size: 1.5rem;
}

.b1_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-top: -40px;
}

.b_1 strong {
  color: #ff2a3a;
  font-weight: 700;
  font-size: 2.5rem;
}

.b_1 p {
  color: #fff;
}

.b_2_content {
  width: 100%;
  height: 100%;
  display: flex;
  filter: blur(4px);
  position: relative;
}

.b_2_content ul {
  width: 100%;
  max-height: 225px;
  overflow-y: auto;
}

.b_2_content li {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b_2_content li div {
  display: flex;
  align-items: center;
  color: #fff;
}

.b_2_content span {
  margin-right: 15px;
}

.b_2_content p {
  opacity: 0.7;
}

.league_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
  z-index: 2;
}

.lw_box {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 15px;
  background: #652929;
  margin: 10px 0;
  border-radius: 10px;
  transition: 0.4s all;
  cursor: pointer;
}

.lw_box:hover {
  box-shadow: rgb(169 169 169) 0px 0px 0.8rem;
}

.lw_box strong {
  color: #fff;
  margin-left: 10px;
}

.lw_box div {
  display: flex;
  align-items: center;
}

.lw_box div img {
  width: 30px;
}

/* prediction_wrap */

.prediction_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pw_banner {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.pw_banner img {
  width: 100%;
}

.pwb_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  bottom: 20px;
}

.pwb_wrap div:nth-child(1) {
  display: flex;
  align-items: center;
}

.pwb_wrap div:nth-child(1) img {
  width: 40px;
  object-fit: contain;
  margin-right: 10px;
}

.pwb_wrap div:nth-child(1) h2 {
  color: #fff;
}

.pwb_wrap div:nth-child(2) {
  padding: 8px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 49px;
}

.pwb_wrap div:nth-child(2) p {
  color: #ff2a3a;
}

.pwb_wrap div:nth-child(2) b {
  margin-left: 10px;
}

.pw_tabs_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  position: relative;
  max-width: 1400px;
}

.pw_tabs_wrap > a {
  display: flex;
  align-items: center;
}

.back {
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  left: 20px;
  cursor: pointer;
  transition: 0.6s all;
}

.back img {
  margin-right: 10px;
}

.back:hover {
  opacity: 0.8;
}

.pw_tabs {
  display: flex;
  align-items: center;
  background: #652929;
  border-radius: 4px;
}

.pw_tabs div {
  width: 110px;
  height: 45px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s all;
  cursor: pointer;
  font-weight: 600;
}

.non_activeTab {
  background: #652929;
}

.non_activeTab:hover {
  box-shadow: rgb(169 169 169) 0px 0px 0.8rem;
}

.active_tab {
  background-color: #ff2a3a;
  border-radius: 4px;
}

.active_tab:hover {
  box-shadow: rgb(136 8 8) 0px 0px 0.8rem;
  background: rgb(136, 8, 8);
  border-color: currentcolor;
}

.pcard_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  transition: 0.6s all;
}

.total-payouts {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 49px;
}

#tsparticles {
  z-index: -12 !important;
}

.total-payouts p {
  color: #ff2a3a;
}

.total-payouts b {
  margin-left: 10px;
}

@media screen and (max-width: 730px) {
  .fpw_content h1 {
    font-size: 2.3rem;
    line-height: 1.2;
  }
  .home_fpw_content h1 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .b_1 strong,
  .fqw_content h2 {
    font-size: 2rem;
  }
  .fqw_box {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .fpw_content h1 {
    font-size: 1.8rem;
    line-height: 1.2;
  }
  .home_fpw_content h1 {
    font-size: 2rem;
    line-height: 1.2;
  }
  .lw_box {
    min-height: 66px;
  }
  .lw_box strong {
    font-size: 0.8rem;
  }
  .pw_tabs_wrap {
    flex-direction: column;
  }
  .back {
    position: unset;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 530px) {
  .lw_box {
    width: 48%;
  }
  .lw_box strong {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .home_fpw_content h1 {
    font-size: 1.3rem;
    line-height: 1.1;
  }
  .sub-sports-title h1 {
    font-size: 2rem;
    line-height: 1.1;
  }
  .banner-img-container {
    height: 180px;
  }
  .fpw_banner img {
    width: 500px;
    min-height: 180px;
    margin-top: 0;
  } 
  .tennis_fpw_content h1 {
    font-size: 2rem;
    line-height: 1.1;
  }
  /* .fpw_description {
    display: none;
  } */
  .total-payouts {
    opacity: 0.5;
  }
}

@media screen and (max-width: 425px) {
  .pwb_wrap div:nth-child(1) img {
    width: 28px;
  }
  .pwb_wrap div:nth-child(1) h2 {
    font-size: 1.2rem;
  }
  .fp-particle-container {
    height: 100vh;
  }
}

@media screen and (max-width: 400px) {
  .home_fpw_content h1 {
    font-size: 1rem;
  }
  .b_2_content p {
    font-size: 0.8rem;
  }
  .pwb_wrap div:nth-child(2) {
    padding: 8px 14px;
  }
}

@media screen and (max-width: 360px) {
  .lw_box strong {
    font-size: 0.8rem;
  }
  .pw_tabs {
    width: 90%;
  }
}
