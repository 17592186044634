.prediction_card {
  width: 32%;
  background: #652929ee;
  border: 1px solid #c40027;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px 0;
  margin-bottom: 20px;
  transition: 0.6s all;
  /* min-height: 470px; */
  transform-style: preserve-3d;
}

.flip_card {
  transform: rotateY(-180deg);
}

.prediction_card h2 {
  color: #ff2a3a;
  font-size: 1.2rem;
  padding: 0 20px 20px 20px;
  text-align: center;
}

.prediction_card hr {
  background-color: #c40027;
  opacity: 1;
  color: #c40027;
  border: none;
  height: 1px;
}

.p_1 {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  flex-direction: column;
}

.p_1 div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  font-style: normal;
}

.p_1 strong {
  font-weight: 700;
}

.p_1 p {
  font-weight: 500;
}

.p_2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
}

.p2_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  position: relative;
}

.p2_div div:not(:last-child) {
  margin-bottom: 10px;
}

#deposited-team {
  position: absolute;
  right: -8px;
  top: 25px;
}

#deposited-team img {
  width: 32px;
  height: 32px;
  border: 2px solid white;
  border-radius: 16px;
}

#deposited-team h5 {
  color: #fff;
  font-size: 0.7rem;
  position: absolute;
  top: 5px;
  left: 3px;
  z-index: 9999;
  transform: rotate(-45deg);
}

.p2_div div:nth-child(1) {
  background-color: #ff2a3a;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #fff;
  transition: 0.4s all;
  cursor: pointer;
}

.p2_div div:nth-child(2) {
  background-color: #fff;
  font-weight: 700;
  color: #ff2a3a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all;
  width: 100%;
  height: 30px;
}

.p2_div div:nth-child(1):hover {
  background-color: #c40027 !important;
  color: #fff;
}

.p_4 {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
}

.p_4 button {
  background: #ff2a3a;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  padding: 15px 10px;
  border: none;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1rem;
  transition: 0.6s all;
}

.p_4 button:hover {
  box-shadow: rgb(136 8 8) 0px 0px 0.8rem;
  background: rgb(136, 8, 8);
}

/* Price Bet Card */

.pricebet_card {
  width: 100%;
  transform: rotateY(-180deg);
}

.pc_1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px 20px 20px;
}

.pc_1 h1 {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ff2a3a;
  margin-left: 15px;
}

.pc_1 img {
  cursor: pointer;
  transition: 0.6s all;
}

.pc_1 img:hover {
  opacity: 0.8;
}

.pc_1 b {
  color: #fff;
  font-weight: 700;
}

.pc_2 {
  width: 100%;
  padding: 0 20px;
  color: #fff;
  font-weight: 600;
}

.pc_2 div {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.pc_2 input {
  border: 1px solid #ff2a3a;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  background-color: transparent;
  text-align: right;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  margin: 10px 0;
  padding: 15px 10px;
}

.pc_2 input:focus {
  outline: none;
}

.pc_2 h5 {
  text-align: right;
  margin: 10px 0 30px 0;
}

.pricebet_card h5 {
  font-size: 0.9rem;
  font-weight: 400;
  opacity: 0.8;
  color: #fff;
}

.pc_3 {
  width: 100%;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  margin-bottom: 30px;
}

.pc_3 > div:nth-child(1) {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 7px;
}

.pc_3 > div:nth-child(1) div {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  transition: 0.6s all;
}

.pc_3 > div:nth-child(1) div:first-child {
  border-radius: 10px 0px 0px 10px;
}
.pc_3 > div:nth-child(1) div:last-child {
  border-radius: 0px 10px 10px 0px;
}

.activeBet {
  background: #ff2a3a;
  color: #fff;
}

.nonActiveBet {
  color: #ff2a3a;
}

.activeValue {
  background-color: #c40027 !important;
  color: #fff !important;
}

/* finished_card */

.finished_card {
}
.prediction-result-num {
  color: white;
  font-size: 22px;
}

.fc_4 {
  width: 100%;
  padding: 20px 20px;
  color: #fff;
}

.fc_4 div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.fc_4 .fc_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  position: relative;
}

.fc_4 .fc_box img:nth-child(1) {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.fc_4 .fc_box div {
  position: absolute;
  left: 80px;
  top: 10px;
}

.fc_4 .fc_box div h5 {
  color: #fff;
  font-size: 0.7rem;
  position: absolute;
  top: 5px;
  left: 2px;
  z-index: 9999;
  transform: rotate(-41deg);
}

.fc_4 div button {
  background: #ceced1;
  border-radius: 4px;
  border: none;
  width: 100px;
  height: 55px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finished_card .p_4 button {
  background-color: #ceced1;
  color: #000;
}

.finished_card .p_4 button:hover {
  background-color: rgb(169, 169, 169);
  box-shadow: rgb(169 169 169) 0px 0px 0.8rem;
}

.activeFinishTab {
  background-color: #c40027 !important;
}

@media screen and (max-width: 1200px) {
  .prediction_card {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .p_1 p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 700px) {
  .fc_box {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .prediction_card {
    width: 100%;
  }
}

.game-cancelled-info {
  color: white;
  text-align: center;
  margin-top: -10px;
  background-color: #ff2a3a;
}