.header_wrap {
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.nav_menu {
  display: none;
}

.menu_drpdwn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.menu_drp_item {
  transition: 0.6s all;
  opacity: 0;
  border-color: rgba(133, 133, 133, 0.1);
  border-style: solid;
  border-width: 1px;
}

.menu_drpdwn div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showDrp {
  width: 90%;
  margin: 0 auto;
  height: auto;
  opacity: 1;
  transition: 0.6s all;
}

.showDrp2 {
  width: 90%;
  margin: 0 auto;
  height: auto;
  opacity: 1;
  transition: 0.6s all;
}

.md_1 {
  display: flex;
  align-items: center;
}

.top_bar {
  width: 100%;
  height: 68px;
  background: #272b2f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 99999;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.c_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.metamask-setup {
  position: absolute;
  z-index: 999999 !important;
  right: 20px;
  top: 20px;
}

.top_bar > img {
  margin-top: 10px;
}

.nav_btns {
  display: flex;
  align-items: center;
}

.nav_btns .nb_1 {
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 50px;
  height: 32px;
}

.nb_1 div:nth-child(1) {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.drp_down div {
  border: 2px solid transparent;
  padding: 10px 15px;
}

.drp_down div:hover {
  border: 2px solid rgb(199, 1, 1);
}

.nb_1 div:nth-child(1) img:nth-child(1) {
  width: 28px;
  object-fit: contain;
}

.drp_down {
  position: absolute;
  top: 55px;
  width: 160px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #652929;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.drp_down div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.drp_down div img {
  object-fit: contain;
  width: 28px;
}

.nb_1 {
  width: 120px;
}

.nav_btns .nb_1 span {
  margin-left: 10px;
  margin-right: 10px;
}

.active {
  background: #ff2a3a;
}

.inactive {
  background: transparent;
}

.nb_2 button {
  background: #ff2a3a;
  border-radius: 49px;
  padding: 8px 20px;
  color: #fff;
  font-weight: 600;
  border: none;
  display: flex;
  align-items: center;
}

.nb_2 button:hover {
  filter: brightness(150%);
}

.nb_2 button:active {
  transform: translateY(1px);
}

.nb_2 img {
  margin-right: 8px;
}

.sidebar {
  width: 318px;
  min-height: 98vh;
  background: #272b2f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  padding: 20px 0 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu_list {
  width: 100%;
  line-height: 50px;
  max-height: 45vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(255, 42, 58);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* .menu_list div:nth-child(1) {
  color: #ceced1;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 90%;
  margin: 8px auto;
  border-radius: 10px;
  border: 2px solid transparent;
} */

.menu_item {
  color: #ceced1;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 90%;
  margin: 8px auto;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
}

.menu_item:hover {
  border: 2px solid rgb(199, 1, 1);
}

.sidebar-menu-title-fs18 {
  font-size: 18px !important;
}

.menu_item img {
  width: 24px;
  height: 24px;
}

.dwn_arrow {
  width: 10px !important;
  height: 5px !important;
  transition: all 0.3s ease-in-out,
}

.menu_item span {
  margin-left: 10px;
}

.sidebar_bottom {
  width: 100%;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar_bottom > img {
  width: 80px;
  object-fit: contain;
  text-align: center;
  margin-bottom: 10px;
}

.divider {
  border: 1px solid rgba(206, 206, 209, 0.3);
}

.sb_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sb_content div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
  margin: 7px 0;
  transition: 0.6s all;
}

.sb_content div img {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.sb_content div img:hover {
  transform: scale(1.1);
}

.sb_content > div:nth-child(1) {
  width: 90px;
}

.sb_content > div:nth-child(2) span {
  margin-right: 10px;
  color: #ceced1;
}

@media screen and (max-width: 992px) {
  .sidebar {
    box-shadow: 0px -3px 4px rgb(0 0 0 / 25%);
    transition: 0.6s all;
    left: -1000px;
  }
  .active_sidebar {
    left: 0;
  }
  .top_bar {
    z-index: 99;
  }
  .nav_menu {
    display: flex;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 9999999;
  }
  .nav_menu svg {
    fill: rgb(255, 42, 58);
    flex-shrink: 0;
  }
  .nav_logo {
    margin-left: 30px;
  }
}

@media screen and (min-height: 700px) {
  .menu_list {
    max-height: 65vh;
  }
}

@media screen and (max-height: 580px) {
  .sidebar {
    max-height: 88vh;
    overflow-y: auto;
    min-height: unset;
    padding: 20px 0 0px 0;
  }
  .sidebar::-webkit-scrollbar {
    width: 2px;
  }
}

@media screen and (max-width: 600px) {
  .top_bar {
    padding: 10px 30px;
    height: max-content;
  }
  .nav_logo {
    margin-left: 0;
  }
  .c_container {
    flex-direction: column;
  }
  .nav_btns {
    margin-top: 6px;
  }
  .nav_btns .nb_1 {
    margin-right: 15px;
  }
}

@media screen and (max-width: 380px) {
  .nav_btns .nb_1 {
    margin-right: 10px;
  }
  .nav_btns .nb_1 span {
    font-size: 0.8rem;
  }
  .top_bar {
    padding: 10px 0px;
  }
}

@media screen and (max-width: 360px) {
  .nb_2 button {
    padding: 8px 13px;
  }
}
