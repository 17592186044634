.league-event {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 18px 15px;
    background: #652929;
    margin: 10px 0;
    border-radius: 10px;
}
.league-event p {
    color: white;
}
.league-event div {
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
    display: flex;
}
.league-event button {
    width: 30%;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid red;
}
.league-event button:active {
    transform: translateY(1px);
}
.score-area{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid white;
}
.event-score-input {
    width: 50px;
    height: 30px;
}