.admin-container {
    width: 100%;
    padding: 1em;
}

.flex-direction-row {
    display: flex;
    align-items: center;
    margin: 1em 0;
}

.league-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px 0;
}

.league {
    width: 28%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    border-radius: 10px;
    cursor: pointer;
    background: #652929;
    color: white;
}
.league div {
    display: flex;
    align-items: center;
}
.league div:hover {
    filter: brightness(150%);
}
.league div:active {
    filter: brightness(120%);
}
.league-title-area {
    width: 80%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #652929;
    padding-left: 1em;
}
.league-title-area:hover {
    filter: brightness(150%);
}
.remove-btn-area {
    width: 20%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
    background: #652929;
    border-left: 1px solid white;
}
