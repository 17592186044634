* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: 0% 0% / 100% 100% no-repeat fixed rgb(74, 4, 4);
}

html {
  scroll-behavior: smooth;
}

.d-none {
  display: none;
}

.main {
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 68px;
  padding-left: 318px;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .main {
    padding-left: 0px;
  }
}

@media screen and (max-width: 600px) {
  .main {
    padding-top: 113px;
  }
}
